import React from 'react';

import { myPhoto, scotland } from '../images';
import { SOCIAL_URLS } from '../utils/constants';

function ProfileCard(props) {
  return (
    <div className="card-started" id="home-card">
      <div className="profile">
        <div
          className="slide"
          style={{ backgroundImage: `url(${scotland})` }}
        ></div>

        <div className="image">
          <img src={myPhoto} alt="" style={{ height: 172, width: 172 }} />
        </div>

        <div className="title">Aakash Tyagi</div>
        <div className="subtitle">Engineer. Mentor.</div>

        <div className="social">
          <a
            target="_blank"
            href={SOCIAL_URLS.github}
            rel="noopener noreferrer"
          >
            <span className="fab fa-github"></span>
          </a>
          <a
            target="_blank"
            href={SOCIAL_URLS.linkedin}
            rel="noopener noreferrer"
          >
            <span className="fab fa-linkedin"></span>
          </a>
          <a
            target="_blank"
            href={SOCIAL_URLS.stackoverflow}
            rel="noopener noreferrer"
          >
            <span className="fab fa-stack-overflow"></span>
          </a>
          <a
            target="_blank"
            href={SOCIAL_URLS.twitter}
            rel="noopener noreferrer"
          >
            <span className="fab fa-twitter"></span>
          </a>

          <a
            target="_blank"
            href={SOCIAL_URLS.youtube}
            rel="noopener noreferrer"
          >
            <span className="fab fa-youtube"></span>
          </a>
        </div>

        {/* <div className="lnks">
          <button className="lnk">
            <span className="text">Download CV</span>
            <ion-icon
              name="cloud-download-outline"
              style={commonStyles.myCustomIonicons}
            ></ion-icon>
          </button>
          <button className="lnk discover">
            <span className="text">Contact Me</span>
            <ion-icon
              name="at-outline"
              style={commonStyles.myCustomIonicons}
            ></ion-icon>
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ProfileCard;
