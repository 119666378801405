export default {
  // These are the ionicons that I added via JS file and not the CSS file that came with the template
  myCustomIonicons: {
    marginLeft: 8,
    fontSize: 20,
    position: 'relative',
    top: 6,
  },
  myCustomIoniconsHeader: {
    fontSize: 20,
    // position: 'relative',
    // top: 6,
  },
  myCustomIconProjects: {
    fontSize: 40,
    color: '#fff',
  },
  projectCardInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
