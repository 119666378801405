export const SOCIAL_URLS = {
  linkedin: 'https://www.linkedin.com/in/aakashtyagi/',
  github: 'https://github.com/aakash-cr7',
  stackoverflow: 'https://stackoverflow.com/users/3908902/aakash',
  twitter: 'https://twitter.com/aakashtyagi07',
  youtube: 'https://www.youtube.com/channel/UCp-4-GL0HYOy2gzY4pSJTYA',
};

export const PROJECT_LINKS = {
  linklsyt: 'https://linklyst.app',
  zulip: 'https://github.com/zulip/zulip',
  youtube: SOCIAL_URLS.youtube,
  invitify: 'https://aakashtyagi.in/invitify/',
};

export const WORK_LINKS = {
  codingNinjas: 'https://codingninjas.com',
  goMmt: 'https://makemytrip.com',
  videoken: 'https://videoken.com',
};
