import React from 'react';

import { PROJECT_LINKS } from '../utils/constants';
import {
  zulipBanner,
  invitify,
  linklystBanner,
  theJSninjaBanner,
} from '../images';
import commonStyles from '../commonStyles';

function ProjectsCard(props) {
  return (
    <div className="card-inner" id="works-card">
      <div className="card-wrap">
        <div className="content works">
          <div className="title">Projects</div>

          <div className="filter-menu filter-button-group">
            <div className="f_btn active">
              <label>
                <input type="radio" name="fl_radio" value="grid-item" />
                All
              </label>
            </div>
            <div className="f_btn">
              <label>
                <input type="radio" name="fl_radio" value="product" />
                Product
              </label>
            </div>
            <div className="f_btn">
              <label>
                <input type="radio" name="fl_radio" value="open-source" />
                Open Source
              </label>
            </div>
            <div className="f_btn">
              <label>
                <input type="radio" name="fl_radio" value="youtube" />
                Youtube
              </label>
            </div>
            {/* <div className="f_btn">
              <label>
                <input type="radio" name="fl_radio" value="design" />
                Design
              </label>
            </div> */}
          </div>

          <div className="row grid-items border-line-v">
            <div className="col col-d-6 col-t-6 col-m-12 grid-item product border-line-h">
              <div className="box-item">
                <div className="image">
                  <a
                    href={PROJECT_LINKS.linklsyt}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linklystBanner} alt="linklyst" />
                    <span className="info" style={commonStyles.projectCardInfo}>
                      <ion-icon
                        name="link"
                        style={commonStyles.myCustomIconProjects}
                      ></ion-icon>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a href={PROJECT_LINKS.linklsyt}>Linklyst</a>
                  {/* <div className="category">Photo</div> */}
                  <div className="category">Product</div>
                </div>
              </div>
            </div>

            <div className="col col-d-6 col-t-6 col-m-12 grid-item product border-line-h">
              <div className="box-item">
                <div className="image">
                  <a
                    href={PROJECT_LINKS.invitify}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={invitify} alt="invitify" />
                    <span className="info" style={commonStyles.projectCardInfo}>
                      <ion-icon
                        name="link"
                        style={commonStyles.myCustomIconProjects}
                      ></ion-icon>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a
                    href={PROJECT_LINKS.invitify}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Invitify
                  </a>
                  <div className="category">Product</div>
                </div>
              </div>
            </div>

            <div className="col col-d-6 col-t-6 col-m-12 grid-item open-source border-line-h">
              <div className="box-item">
                <div className="image">
                  <a
                    href={PROJECT_LINKS.zulip}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={zulipBanner} alt="zulip" />
                    <span className="info" style={commonStyles.projectCardInfo}>
                      <ion-icon
                        name="link"
                        style={commonStyles.myCustomIconProjects}
                      ></ion-icon>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a
                    href={PROJECT_LINKS.zulip}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Zulip
                  </a>
                  <div className="category">Open source</div>
                </div>
              </div>
            </div>

            {/* A good pop to display some information */}
            {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item design border-line-h">
              <div className="box-item">
                <div className="image">
                  <a href="#popup-1" className="has-popup-media">
                    <img src={work4} alt="" />
                    <span className="info">
                      <span className="ion ion-images"></span>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a href="#popup-1" className="name has-popup-media">
                    Mobile Application
                  </a>
                  <div className="category">Design</div>
                </div>
                <div id="popup-1" className="popup-box mfp-fade mfp-hide">
                  <div className="content">
                    <div className="image">
                      <img src={work4} alt="" />
                    </div>
                    <div className="desc">
                      <div className="post-box">
                        <h1>Mobile Application</h1>
                        <div className="blog-detail">Design</div>
                        <div className="blog-content">
                          <p>
                            So striking at of to welcomed resolved. Northward by
                            described up household therefore attention.
                            Excellence decisively nay man yet impression for
                            contrasted remarkably.
                          </p>
                          <p>
                            Forfeited you engrossed but gay sometimes explained.
                            Another as studied it to evident. Merry sense given
                            he be arise. Conduct at an replied removal an
                            amongst. Remaining determine few her two cordially
                            admitting old.
                          </p>
                          <blockquote>
                            Vestibulum ante ipsum primis in faucibus orci luctus
                            et ultrices posuere cubilia Curae; Pellentesque
                            suscipit.
                          </blockquote>
                          <p>
                            Tiled say decay spoil now walls meant house. My mr
                            interest thoughts screened of outweigh removing.
                            Evening society musical besides inhabit ye my. Lose
                            hill well up will he over on. Increasing sufficient
                            everything men him admiration unpleasing sex.
                          </p>
                          <ul className="list-style">
                            <li>Greatest properly off ham exercise all.</li>
                            <li>
                              Unsatiable invitation its possession nor off.
                            </li>
                            <li>
                              All difficulty estimating unreserved increasing
                              the solicitude.
                            </li>
                          </ul>
                          <p>
                            Unpleasant astonished an diminution up partiality.
                            Noisy an their of meant. Death means up civil do an
                            offer wound of.
                          </p>
                        </div>
                        <a href="#" className="button">
                          <span className="text">View Project</span>
                          <span className="arrow"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item photo border-line-h">
              <div className="box-item">
                <div className="image">
                  <a href="images/works/work5.jpg" className="has-popup-image">
                    <img src={work5} alt="" />
                    <span className="info">
                      <span className="ion ion-image"></span>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a
                    href="images/works/work5.jpg"
                    className="name has-popup-image"
                  >
                    Gereal Travels
                  </a>
                  <div className="category">Photo</div>
                </div>
              </div>
            </div> */}

            {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item music border-line-h">
              <div className="box-item">
                <div className="image">
                  <a
                    href="https://w.soundcloud.com/player/?visual=true&url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F221650664&show_artwork=true"
                    className="has-popup-music"
                  >
                    <img src={work8} alt="" />
                    <span className="info">
                      <span className="ion ion-music-note"></span>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a href="#" className="name has-popup">
                    Daylight Entrance
                  </a>
                  <div className="category">Music</div>
                </div>
              </div>
            </div> */}

            <div className="col col-d-6 col-t-6 col-m-12 grid-item youtube border-line-h">
              <div className="box-item">
                <div className="image">
                  <a
                    href={PROJECT_LINKS.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={theJSninjaBanner} alt="zulip" />
                    <span className="info" style={commonStyles.projectCardInfo}>
                      <ion-icon
                        name="link"
                        style={commonStyles.myCustomIconProjects}
                      ></ion-icon>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a
                    href={PROJECT_LINKS.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    thJSninjas
                  </a>
                  <div className="category">Youtube</div>
                </div>
              </div>
            </div>

            {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item design border-line-h">
              <div className="box-item">
                <div className="image">
                  <a href="#popup-2" className="has-popup-media">
                    <img src={work7} alt="" />
                    <span className="info">
                      <span className="ion ion-images"></span>
                    </span>
                  </a>
                </div>
                <div className="desc">
                  <a href="#popup-2" className="name has-popup-media">
                    Social Website
                  </a>
                  <div className="category">Design</div>
                </div>
                <div id="popup-2" className="popup-box mfp-fade mfp-hide">
                  <div className="content">
                    <div className="image">
                      <img src={work7} alt="" />
                    </div>
                    <div className="desc">
                      <div className="post-box">
                        <h1>Mobile Application</h1>
                        <div className="blog-detail">Design</div>
                        <div className="blog-content">
                          <p>
                            So striking at of to welcomed resolved. Northward by
                            described up household therefore attention.
                            Excellence decisively nay man yet impression for
                            contrasted remarkably.
                          </p>
                          <p>
                            Forfeited you engrossed but gay sometimes explained.
                            Another as studied it to evident. Merry sense given
                            he be arise. Conduct at an replied removal an
                            amongst. Remaining determine few her two cordially
                            admitting old.
                          </p>
                          <blockquote>
                            Vestibulum ante ipsum primis in faucibus orci luctus
                            et ultrices posuere cubilia Curae; Pellentesque
                            suscipit.
                          </blockquote>
                          <p>
                            Tiled say decay spoil now walls meant house. My mr
                            interest thoughts screened of outweigh removing.
                            Evening society musical besides inhabit ye my. Lose
                            hill well up will he over on. Increasing sufficient
                            everything men him admiration unpleasing sex.
                          </p>
                          <ul className="list-style">
                            <li>Greatest properly off ham exercise all.</li>
                            <li>
                              Unsatiable invitation its possession nor off.
                            </li>
                            <li>
                              All difficulty estimating unreserved increasing
                              the solicitude.
                            </li>
                          </ul>
                          <p>
                            Unpleasant astonished an diminution up partiality.
                            Noisy an their of meant. Death means up civil do an
                            offer wound of.
                          </p>
                        </div>
                        <a href="#" className="button">
                          <span className="text">View Project</span>
                          <span className="arrow"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="clear"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard;
