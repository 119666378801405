import React from 'react';
import { WORK_LINKS } from '../utils/constants';

function ResumeCard(props) {
  return (
    <div className="card-inner" id="resume-card">
      <div className="card-wrap">
        <div className="content resume">
          <div className="title">Work</div>

          <div className="row">
            <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
              <div className="resume-title border-line-h">
                <div className="icon">
                  <i className="ion ion-briefcase"></i>
                </div>
                <div className="name">Experience</div>
              </div>
              <div className="resume-items">
                <div className="resume-item border-line-h active">
                  <div className="date">2019 - Present</div>
                  <div className="name">SWE & Mentor</div>
                  <div className="company">
                    <a
                      href={WORK_LINKS.codingNinjas}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Coding Ninjas
                    </a>
                  </div>
                  <p>One of the best ed-tech startup in India.</p>
                </div>
                <div className="resume-item border-line-h">
                  <div className="date">2018 - 2019</div>
                  <div className="name">Software Enginner</div>
                  <div className="company">
                    <a
                      href={WORK_LINKS.goMmt}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Goibibo-Makemytrip
                    </a>
                  </div>
                  <p>
                    India's number one travel application that lets you book
                    hotels, flights, trains etc.
                  </p>
                </div>
                <div className="resume-item">
                  <div className="date">20017 - 2018</div>
                  <div className="name">Software Enginner</div>
                  <div className="company">
                    <a
                      href={WORK_LINKS.videoken}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VideoKen
                    </a>
                  </div>
                  <p>
                    Provides rich indexed videos to users, track & improve
                    engagement levels
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
              <div className="resume-title border-line-h">
                <div className="icon">
                  <i className="ion ion-university"></i>
                </div>
                <div className="name">Education</div>
              </div>
              <div className="resume-items">
                <div className="resume-item border-line-h">
                  <div className="date">2006 - 2008</div>
                  <div className="name">Art University</div>
                  <div className="company">New York</div>
                  <p>
                    Bachelor's Degree in Computer Science ABC Technical
                    Institute, Jefferson, Missouri
                  </p>
                </div>
                <div className="resume-item border-line-h">
                  <div className="date">2005 - 2006</div>
                  <div className="name">Programming Course</div>
                  <div className="company">Paris</div>
                  <p>Coursework - Git, WordPress, Javascript, iOS, Android.</p>
                </div>
                <div className="resume-item">
                  <div className="date">2004 - 2005</div>
                  <div className="name">Web Design Course</div>
                  <div className="company">London</div>
                  <p>
                    Converted Photoshop layouts to web pages using HTML, CSS,
                    and JavaScript
                  </p>
                </div>
              </div>
            </div> */}

            <div className="clear"></div>
          </div>
        </div>

        {/* <div className="content skills">
          <div className="title">My Skills</div>

          <div className="row">
            <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
              <div className="skills-list">
                <div className="skill-title border-line-h">
                  <div className="icon">
                    <i className="ion ion-easel"></i>
                  </div>
                  <div className="name">Design</div>
                </div>
                <ul>
                  <li className="border-line-h">
                    <div className="name">Web Design</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '90%' }}
                      ></div>
                    </div>
                  </li>
                  <li className="border-line-h">
                    <div className="name">Write Music</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '65%' }}
                      ></div>
                    </div>
                  </li>
                  <li className="border-line-h">
                    <div className="name">Photoshop</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '75%' }}
                      ></div>
                    </div>
                  </li>
                  <li>
                    <div className="name">Graphic Design</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '85%' }}
                      ></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
              <div className="skills-list dotted">
                <div className="skill-title border-line-h">
                  <div className="icon">
                    <i className="ion ion-flag"></i>
                  </div>
                  <div className="name">Languages</div>
                </div>
                <ul>
                  <li className="border-line-h">
                    <div className="name">English</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '90%' }}
                      ></div>
                    </div>
                  </li>
                  <li className="border-line-h">
                    <div className="name">German</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '60%' }}
                      ></div>
                    </div>
                  </li>
                  <li className="border-line-h">
                    <div className="name">Italian</div>
                    <div className="progress">
                      <div
                        className="percentage"
                        style={{ width: '30%' }}
                      ></div>
                    </div>
                  </li>
                  <li>
                    <div className="name">French</div>
                    <div className="progress ">
                      <div
                        className="percentage"
                        style={{ width: '70%' }}
                      ></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
              <div className="skills-list circles">
                <div className="skill-title border-line-h">
                  <div className="icon">
                    <i className="ion ion-code"></i>
                  </div>
                  <div className="name">Coding</div>
                </div>
                <ul>
                  <li>
                    <div className="name">WordPress</div>
                    <div className="progress p90">
                      <span>90%</span>
                    </div>
                  </li>
                  <li>
                    <div className="name">PHP / MYSQL</div>
                    <div className="progress p75">
                      <span>75%</span>
                    </div>
                  </li>
                  <li>
                    <div className="name">Angular / JavaScript</div>
                    <div className="progress p85">
                      <span>85%</span>
                    </div>
                  </li>
                  <li>
                    <div className="name">HTML / CSS</div>
                    <div className="progress p95">
                      <span>95%</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
              <div className="skills-list list">
                <div className="skill-title border-line-h">
                  <div className="icon">
                    <i className="ion ion-android-list"></i>
                  </div>
                  <div className="name">Knowledge</div>
                </div>
                <ul>
                  <li>
                    <div className="name">Website hosting</div>
                  </li>
                  <li>
                    <div className="name">iOS and android apps</div>
                  </li>
                  <li>
                    <div className="name">Create logo design</div>
                  </li>
                  <li>
                    <div className="name">Design for print</div>
                  </li>
                  <li>
                    <div className="name">Modern and mobile-ready</div>
                  </li>
                  <li>
                    <div className="name">Advertising services include</div>
                  </li>
                  <li>
                    <div className="name">Graphics and animations</div>
                  </li>
                  <li>
                    <div className="name">Search engine marketing</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="clear"></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ResumeCard;
