import React from 'react';
import commonStyles from '../commonStyles';

function Header(props) {
  return (
    <header className="header">
      <div className="top-menu">
        <ul>
          <li className="active">
            <a href="#about-card">
              {/* <span className="icon ion-person"></span> */}
              <ion-icon
                name="person-outline"
                style={commonStyles.myCustomIoniconsHeader}
              ></ion-icon>
              <span className="link">About</span>
            </a>
          </li>
          <li>
            <a href="#resume-card">
              {/* <span className="icon ion-android-list"></span> */}
              <ion-icon
                name="document-text-outline"
                style={commonStyles.myCustomIoniconsHeader}
              ></ion-icon>
              <span className="link">Work</span>
            </a>
          </li>
          <li>
            <a href="#works-card">
              {/* <span className="icon ion-paintbrush"></span> */}
              <ion-icon
                name="color-palette-outline"
                style={commonStyles.myCustomIoniconsHeader}
              ></ion-icon>
              <span className="link">Projects</span>
            </a>
          </li>
          {/* <li>
            <a href="#blog-card">
              <span className="icon ion-chatbox-working"></span>
              <span className="link">Blog</span>
            </a>
          </li> */}
          <li>
            <a href="#contacts-card">
              <span className="icon ion-at"></span>
              <span className="link">Contact</span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
