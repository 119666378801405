import React from 'react';
import {
  Loader,
  Background,
  Header,
  ProfileCard,
  AboutCard,
  ResumeCard,
  ProjectsCard,
  Blog,
  ContactCard,
} from './';

function App() {
  return (
    <div className="page new-skin">
      <Loader />
      <Background />

      <div
        className="container opened"
        data-animation-in="fadeInLeft"
        data-animation-out="fadeOutLeft"
      >
        <Header />
        <ProfileCard />

        <AboutCard />
        <ResumeCard />
        <ProjectsCard />
        <Blog />

        <ContactCard />
      </div>
    </div>
  );
}

export default App;
